import React from 'react';
import AdmissionBanner from './AdmissionBanner';

const OnlineAdmissionProcess = () => {
    return (
        <>
            <AdmissionBanner />
            <div className="container mb-4 mt-5">
                <div className="mb-3">
                    <p className="text-muted admission_description">Riverdale School will be now on accepting online applications as well.
                        We would like to request you to complete (online application form/link) to secure your child’s place.
                        Each applicant must go through the same initial process while applying for the admission.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted admission_description">As the school is currently closed due to guidelines for COVID-19
                        precautions and safety we are offering online application system. Contact our Admissions
                        Manager on Main Branch: +880244612414, +8801552356559, Branch 2: +8801821467266, or email
                        riverdale@gmail.com for more information.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted admission_description">We also have option for online payment.</p>
                </div>
                <div className="mb-3">
                    <h2 className="admission_step_header">STEP 1</h2>
                    <p className="text-muted admission_list_step">We also have option for online payment.</p>
                </div>
                <div className="mb-3">
                    <h2 className="admission_step_header">STEP 2</h2>
                    <p className="text-muted about_description">1. Please submit the following to the Riverdale School Admissions Office:</p>
                    <ul className='admission_step_2_ul'>
                        <li className="text-muted admission_list_step">Students Birth Certificate</li>
                        <li className="text-muted admission_list_step">Parents ID copy</li>
                        <li className="text-muted admission_list_step">Student Immunization Record</li>
                        <li className="text-muted admission_list_step">4 Copies of Student Photographs, Passport Size</li>
                        <li className="text-muted admission_list_step">Previous Year’s School Reports</li>
                        <li className="text-muted admission_list_step">Original Transfer Certificate*</li>
                    </ul>
                    <p className="text-muted about_description">2. BDT (XXX) Application Fee (non-refundable).</p>
                </div>
                <div className="mb-3">
                    <h2 className="admission_step_header">STEP 3</h2>
                    <ul className='admission_step_3_ul'>
                        <li className="text-muted admission_list_step">When all of the requisite paperwork and the Application Fee has been received, you will be contacted by the Admissions office to arrange an Entrance Test.</li>
                        <li className="text-muted admission_list_step">We will invite those applicants to our Admissions Office where they will undertake the Entrance Test and attend an interview (if required).</li>
                        <li className="text-muted admission_list_step">Applicants will only be invited for Entrance tests where there are places available in the year group for which they are applying.</li>
                        <li className="text-muted admission_list_step">4 Copies of Student Photographs, Passport Size</li>
                        <li className="text-muted admission_list_step">Previous Year’s School Reports</li>
                        <li className="text-muted admission_list_step">Original Transfer Certificate*</li>
                    </ul>
                </div>
                <div className="mb-3">
                    <h2 className="admission_step_header">STEP 4</h2>
                    <p className="text-muted about_description">After the Entrance test and result the admin office will contact within 5 to 7 working days, the outcome will be one of the following:</p>
                    <ul className='admission_step_4_ul'>
                        <li className="text-muted admission_list_step">Placement Confirmation</li>
                        <li className="text-muted admission_list_step">Conditional Offer</li>
                        <li className="text-muted admission_list_step">Name on the waiting list</li>
                        <li className="text-muted admission_list_step">Re-entrance Tests</li>
                        <li className="text-muted admission_list_step">Not Accepted</li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default OnlineAdmissionProcess;