import React from 'react';
import ExtraCarricularActivitiesBanner from './ExtraCarricularActivitiesBanner';

const ExtraCarricularActivities = () => {
    return (
        <>
            <ExtraCarricularActivitiesBanner />
            <div className="container mb-5 mt-5">
                <div className="mb-3">
                    <h4 className="header_curricullum_overview">REAL WORLD LEARNING</h4>
                    <p className="text-muted curricullum_rds_description ">ECA helps kids to develop their sense
                        of moral values and attitudes, innovation skills and creativity. RDS embraces the fact that
                        through participation in ECA students not only learn to communicate but also learn to
                        cooperate with the other to enrich their life experience. ECA is a platform where every
                        individuals gets practical and hands on activities to study through real world scenario.
                        ECA helps to find child’s area of interest and potential and allows student to put their
                        special knowledge into practice. There is no doubt that every child is different and carries
                        different talent. Our ECA program is designed in a way to bring those skills out of them as
                        we follow very student-centered projects and programs. RDS look forward to make every
                        learner’s life more challenging and interesting through various creative ECA projects. We
                        ensure to prove safe and encouraging school environment in order to provide support every
                        student’s skill development.
                    </p>
                </div>
                <div className="mb-3">
                    <h4 className="header_curricullum_areas">SCHOOL PROGRAM</h4>
                    <p className="text-muted header_curricullum_areas">CLUBS</p>
                    <ul className='admission_step_3_ul'>
                        <li className="text-muted admission_list_step">Taekwondo Club</li>
                        <li className="text-muted admission_list_step">Bangla Debate Club</li>
                        <li className="text-muted admission_list_step">Art and Paper Craft Club</li>
                    </ul>
                </div>
                <div className="mb-3">
                    <h4 className="header_curricullum_areas">AFTER SCHOOL PROGRAM (PAID PROGRAM)</h4>
                    <ul className='admission_step_3_ul'>
                        <li className="text-muted admission_list_step">Extra-Curricular Activities</li>
                        <li className="text-muted admission_list_step">Fees and charges</li>
                        <li className="text-muted admission_list_step">Schedule</li>
                        <li className="text-muted admission_list_step">ECA faculty</li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default ExtraCarricularActivities;