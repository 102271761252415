import React from 'react';
import Mission from '../../Images/Mission.png';
import Vision from '../../Images/Vision.png';
import AboutUsBanner from './AboutUsBanner';


const AboutUs = () => {
    return (
        <>
            <AboutUsBanner />
            <div className="container mb-4 mt-5">
                <div className="mt-0">
                    <h3 className="header_about_our_sec">
                        <span className="header_about_our">About our</span>
                        <span className="header_about"> Riverdale School</span>
                        <span className="header_at"> at a glance</span>
                    </h3>
                    <h2 className="header_about_riverdale">Where did it all begin?</h2>
                    <p className="text-muted about_description">Et culpa irure occaecat enim tempor laborum.
                        Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt
                        nostrud anim voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing
                        incididunt nisi. Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim anim
                        exercitation Lorem consectetur commodo. Et culpa irure occaecat enim tempor laborum. Id anim sint
                        occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt nostrud anim
                        voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing incididunt nisi.
                        Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim anim exercitation Lorem
                        consectetur commodo.Et culpa irure occaecat enim tempor laborum. Id anim sint occaecat amet laboris
                        pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt nostrud anim voluptate est sint sunt enim.
                        Fugiat cillum est sint ipsum nulla magna adipisicing incididunt nisi. Qui amet ex elit in veniam sunt.
                        Non velit culpa occaecat nisi laborum anim anim exercitation Lorem consectetur commodo.
                    </p>
                </div>

                <div className="row g-3 mt-5">
                    <div className="col-md-6">
                        <img src={Mission} className=" img-fluid" style={{ width: '100%', height: '280px' }} alt="Mission" border="0" />
                    </div>
                    <div className="col-md-6">
                        <div className="ps-md-5">
                            <h3 className="header_our_mission">Our Mission</h3>
                            <p className="text-muted our_mission_description ">Et culpa irure occaecat enim tempor laborum.
                                Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt
                                nostrud anim voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing
                                incididunt nisi. Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim
                                anim exercitation Lorem consectetur commodo. Et culpa irure occaecat enim tempor laborum. Id
                                anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt
                                nostrud anim voluptate est sint sunt enim.Fugiat cillum est sint ipsum nulla magna adipisicing
                                incididunt nisi.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row g-3 mt-5">
                    <div className="col-md-6">
                        <h3 className="header_our_vision">Our Vision</h3>
                        <p className="text-muted our_vision_description pe-md-5">Et culpa irure occaecat enim tempor laborum.
                            Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt
                            nostrud anim voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing
                            incididunt nisi. Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim
                            anim exercitation Lorem consectetur commodo. Et culpa irure occaecat enim tempor laborum. Id
                            anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt
                            nostrud anim voluptate est sint sunt enim.Fugiat cillum est sint ipsum nulla magna adipisicing
                            incididunt nisi.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={Vision} className=" img-fluid" style={{ width: '100%', height: '280px' }} alt="Vision" border="0" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUs;