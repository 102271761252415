import React from 'react';
import CurriculumOverviewBanner from './CurriculumOverviewBanner';

const CurriculumOverview = () => {
    return (
        <>
            <CurriculumOverviewBanner />
            <div className="container mb-4 mt-5">
                <div className="mb-3">
                    <h4 className="header_curricullum_overview">CURRICULUM OVERVIEW</h4>
                    <p className="text-muted curricullum_rds_description ">RDS is designed to nurture a child
                        in all dimensions of his/her life. It offers environment to bring up socially
                        responsible global citizens by following a key curriculum. Every child is unique
                        and the learning approaches of each child is different.
                        So we ensure to cater learners of different abilities, interests and background.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted curricullum_rds_description">RDS follows GCE Syllabi to teach all the
                        subjects and English is the medium of instruction.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted curricullum_rds_description">At RDS learning is pursued through-</p>
                </div>
                <div className="mb-3">
                    <h4 className="header_curricullum_early_years">EYFS (Early Years Foundation Stage) for Early Years (PG to KG2)</h4>
                    <p className="text-muted early_years_description ">RDS believes in nurturing a child in the best way
                        possible to cover overall development of a child. We want them to be capable learners by providing
                        an environment where they can express themselves independently as well as expand their knowledge.
                        Hence we chose EYFS as the medium of their learning.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted early_years_description">EYFS focuses on prime areas of learning which are
                        critical for the development of young children and are fundamental to more structured learning as
                        they get older. It provides a broad and overall development plan that gives a path for educational
                        success and also supports a child to become all-rounded, spiritual and a thoughtful individuals.
                    </p>
                </div>
                <div className="mb-3">
                    <h2 className="header_curricullum_areas">Areas of learning</h2>
                    <p className="text-muted header_curricullum_areas">The areas of learning are:</p>
                    <ul className='admission_step_3_ul'>
                        <li className="text-muted admission_list_step">When all of the requisite paperwork and the Application Fee has been received, you will be contacted by the Admissions office to arrange an Entrance Test.</li>
                        <li className="text-muted admission_list_step">We will invite those applicants to our Admissions Office where they will undertake the Entrance Test and attend an interview (if required).</li>
                        <li className="text-muted admission_list_step">Applicants will only be invited for Entrance tests where there are places available in the year group for which they are applying.</li>
                        <li className="text-muted admission_list_step">4 Copies of Student Photographs, Passport Size</li>
                        <li className="text-muted admission_list_step">Previous Year’s School Reports</li>
                        <li className="text-muted admission_list_step">Original Transfer Certificate*</li>
                    </ul>
                </div>
                <div className="mb-3">
                    <h4 className="header_curricullum_middle_years">PLSC for Primary Years and Middle Years (Grade 1 to Grade 8)</h4>
                    <p className="text-muted middle_years_description ">RDS main motive is to improve each
                        learner’s lives through learning. PLSC has been designed to provide a solid foundation
                        to acquire outstanding learning experience. The curriculum is so well structured with
                        the strong and enough content and methodology to enable our child to become eligible to
                        confidently undertake higher level education specially to prepare students for IGCSE.
                        RDS helps children to achieve international benchmark of academic success through PLSC.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted middle_years_description">
                        In PY and MY, the focus is on learning good fundamental subjects. At this stage the emphasis is on exposing
                        students to opportunities in a wide range of subjects are as follows:
                    </p>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered text-muted text-center table-hover-cells">
                        <thead>
                            <tr>
                                <th className="w-50">English Language</th>
                                <th className="w-50">English Literature</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Bengali</td>
                                <td>Individuals and Societies (History, Geography)</td>
                            </tr>
                            <tr>
                                <td >Arabic as Second Language</td>
                                <td>Science</td>
                            </tr>
                            <tr>
                                <td >Math</td>
                                <td>Arts</td>
                            </tr>
                            <tr>
                                <td >Computer Studies (ICT)</td>
                                <td>Moral Education</td>
                            </tr>
                            <tr>
                                <td >PE/health education</td>
                                <td>English Literature</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default CurriculumOverview;