import React from 'react';
import image_50 from '../../Images/image_50.png';
import image_49 from '../../Images/image_49.png';
import image_52 from '../../Images/image_52.png';
import AcademicProgramBanner from './AcademicProgramBanner';

const AcademicProgram = () => {
    return (
        <>
            <AcademicProgramBanner />
            <div className="container mb-5 mt-5">
                <div className="row g-3 pt-5 mb-5">
                    <div className="col-md-6">
                        <img src={image_50} className=" img-fluid" style={{ width: '100%', height: '280px' }} alt="image_50" border="0" />
                    </div>
                    <div className="col-md-6">
                        <div className="ps-md-5">
                            <h6 className="header_early_years">EARLY YEARS (EY)</h6>
                            <h4 className="header_form_play">From Play Groups to Kindergarten 2 (PG to KG2)</h4>
                            <p className="text-muted early_years_description ">We welcome the child age from 4 to 6 
                                in Early Years Program to participate the classroom from Play Groups to Kindergarten 2 
                                (PG to KG2). Our children in EY enjoy and experience throughout the year as they are exposed 
                                to different areas of learning. Learning process is perused through various fun and playful 
                                activities to develop the sense of spirituality and to build the foundation for academic success.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row g-3 pt-2 mb-5">
                    <div className="col-md-6">
                        <h6 className="header_primary_years">PRIMARY YEARS (PY)</h6>
                        <h4 className="header_from_grade">From Grade 1 to 4</h4>
                        <p className="text-muted primary_years_description pe-md-5">In PY program child can enroll from 
                            Grade 1 to 4. To continue the development of student’s academic success Riverdale School keeps 
                            on nurturing the innate curiosity and learning skills of each child. We expose students to wide 
                            range of subject areas to keep them motivated to become creative, analytical and continue to 
                            achieve academic success.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={image_49} className=" img-fluid" style={{ width: '100%', height: '280px' }} alt="image_49" border="0" />
                    </div>
                </div>
                <div className="row g-3 pt-5 mb-5">
                    <div className="col-md-6">
                        <img src={image_52} className=" img-fluid" style={{ width: '100%', height: '280px' }} alt="image_52" border="0" />
                    </div>
                    <div className="col-md-6">
                        <div className="ps-md-5">
                            <h6 className="header_middle_years">MIDDLE YEARS (MY)</h6>
                            <h4 className="header_from_grade">From Grade 5 to 8</h4>
                            <p className="text-muted middle_years_description ">MY is the program that bridges the 
                                primary years and higher secondary programs engaging students from grade 5 to 8 in an 
                                enriching and appropriate academic program. The objective of this program is to instill 
                                learning skills to help them get ready for higher education.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AcademicProgram;