import React from 'react';
import Gallery from '../main_home_page/Gallery';
import OutdoorClassroomBanner from './OutdoorClassroomBanner';

const OutdoorClassroom = () => {
    return (
        <>
            <OutdoorClassroomBanner />
            <div className="container mb-5 mt-5">
                <div className="row g-3 ">
                    <div className="col-md-12">
                        <div className="mt-0">
                            <h4 className="header_real_world_learning">REAL WORLD LEARNING</h4>
                            <p className="text-muted nature_description">Nature is an open source of 
                                inquisitiveness and motivation for children. Connecting children with nature helps them 
                                to grow physically and mentally. We can’t deny the fact that nature is equally important 
                                to build sense of spirituality. Furthermore, interacting with natural environment allows 
                                children to learn by doing and experiment with ideas. A natural world is an open-ended 
                                laboratory where children become innovative, imaginative, and problem-solver.
                            </p>
                            <p className="text-muted lgs_description">LGS also offers activities that 
                                is a class without wall. The theme based tour and trip are given to students to understand
                                the world around them. Such trips provide every student with real-world experiences.
                            </p>
                            <p className="text-muted field_trips_description">Field trips to-</p>
                            <ul className='field_trips_ul'>
                                <li className="text-muted field_trips_list">Library</li>
                                <li className="text-muted field_trips_list">Art Museum</li>
                                <li className="text-muted field_trips_list">Monuments</li>
                                <li className="text-muted field_trips_list">Botanical Garden</li>
                                <li className="text-muted field_trips_list">Theatre</li>
                                <li className="text-muted field_trips_list">Zoo</li>
                                <li className="text-muted field_trips_list">Farm</li>
                            </ul>
                            <p className="text-muted trip_done">Weekly trip to the closest park (closer look to nature)</p>
                        </div>
                    </div>
                </div>
                <div className="row g-3 mt-5">
                    <Gallery />
                </div>
            </div>
        </>
    );
};

export default OutdoorClassroom;