import React from 'react';
import AlumniBanner from './AlumniBanner';

const Alumni = () => {
    return (
        <>
            <AlumniBanner />
            <div className="container mb-5 mt-5">
                <div className="mb-3">
                    <p className="text-muted alumni_description">Riverdale School Alumni was founded with the 
                        intention to serve as a platform for all the graduates and past students to connect/reconnect 
                        with old friends, discuss ideas and have fun to share interesting stories or collaborate on 
                        productive projects, all in the spirit of friendship and nostalgia. We organize reunions and 
                        events to foster unity among alumni members, bridging the gap between the old and the new.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted alumni_description">At Riverdale School Alumni, we celebrate greatness. 
                        Greatness is for all of us, not just the selected few in our midst. It is not just about our scholastic 
                        achievements but also embodying the legacy of our core ideals and values. It is not about lowering 
                        expectations, rather about raising them for every last one of us Riverdale School Alumni. Because 
                        greatness is not in one special place, and it is not in one special person. Greatness is wherever 
                        somebody is trying to find it. Find your greatness with us and continue your story. Because once you 
                        are a Riverdaleian, you are always a Riverdale.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted alumni_description">For inquires about Riverdale School Alumni membership and 
                        its various activities, please contact via
                    </p>
                    <p className="text-muted alumni_description">Email: riverdaleschoolalumni@gmail.com</p>
                    <p className="text-muted alumni_description">www.facebook.com/RiverdaleInternationalSchool</p>
                </div>
            </div>






        </>
    );
};

export default Alumni;